import React from "react"

// Images
import Step1 from "../assets/how-to-financial-step1.jpg"
import Step2 from "../assets/how-to-financial-step2.jpg"
import Vid from "../assets/how-to-financial-video.mp4"

const HowToFinancialPage = () => {
  return (
    <>
      <img width="100%" src={Step1} alt="Step 1" />
      <img width="100%" src={Step2} alt="Step 2" />
      <video width="100%" controls>
        <source src={Vid} type="video/mp4" />
        <track kind="captions" />
      </video>
    </>
  )
}

export default HowToFinancialPage
